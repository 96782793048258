import React, { useState, useContext } from 'react';
import Particules from "../shared/Particules";
import UserContext from '../shared/UserContext.js';
import FormForNumbers from "./FormForNumbers.jsx";
import FormString from "./FormString.jsx";
import FormDeroulant from "./FormDeroulant.jsx";

export default function FormulaireReponses() {
    const { session, setSession } = useContext(UserContext);
    const [ checkWinList, setCheckWinList ] = useState([]);
    const [formContent, setFormContent] = useState('');

    const enigmes = session.route;
    const enigme = session.currentPage;

    const handleFormChange = (index, bool) => {
        let list = 0;
        if (checkWinList.length < 1) {
            list = new Array(enigmes[enigme].questions.length).fill(false);
        } else {
            list = checkWinList;
            console.log(" copy list");
        }
        const checkWinListTemp = list.map((c, i) => {
        if (i === index) {
            return bool;
        } else {
            return c;
        }
        });
        setCheckWinList(checkWinListTemp);
        console.log("Etat des réponses: "+checkWinList);
    }

    const handleSubmit = (e) => {
      e.preventDefault();
      let checkWinNumber = checkWinList.reduce(
        (acc, curr) => { return (curr === true) ? acc = acc + 1 : acc  },
        0,
      );
      console.log("Nombre bonnes réponses: "+checkWinNumber);
      console.log("Nombre bonnes réponses attendues: "+checkWinList.length);
      if (checkWinNumber >= checkWinList.length && checkWinList.length > 0) {
        if(session.step < 3) { // CAD jeu pas encore terminé
            console.log("DERNIERE ENIGME ATTEINTE");
            setSession({session, type: 'victoire', value: new Date().toISOString()});
        } 
        setFormContent("win");
      } else {
        setFormContent('tryAgain');
      }
    };

    const ResetAndBack = () => {
        Reset();
        setSession({session, type:"renderMenu"});
    }

    const Reset = () => {
        setFormContent(1);
    }

    const renderForm = () => {

        if ( formContent === "win" ) {
            return (
                <div className="container text-center">
                    <div className="row boup confettis">
                        <h1>{session.route[session.currentPage].lib_win_title}</h1>
                        <h4>{session.route[session.currentPage].lib_win_texte}</h4>
                    </div>
                    <Particules/>
                    <button
                        onClick={() => ResetAndBack()}
                        className="btn btn-primary btn-lg btn-block"
                    >
                        Revenir aux indices
                    </button>
                </div>

            );
        } else if ( formContent === "tryAgain" ) {
            return (
                <div className="container text-center">
                    <div className="row boup">
                        <h1>Mauvaise réponse !</h1>
                    </div>
                    <button
                        onClick={() => Reset()}
                        className="btn btn-primary btn-lg btn-block"
                    >
                        Tenter une nouvelle vérification
                    </button>
                    <button
                        onClick={() => ResetAndBack()}
                        className="btn btn-primary btn-lg btn-block"
                    >
                        Revenir aux indices
                    </button>
                </div>
            );
        } else {
            return (
                <div className="container text-center confettis">
                    <h3>{enigmes[enigme].lib}</h3>
                    <div className="row boup">
                        {enigmes[enigme].questions.map((tab, index) => {

                            if (typeof enigmes[enigme].answers[index] === 'string') {
                                return (
                                    <div key={index}>
                                        <FormString index={index} handleChange={handleFormChange} answer={enigmes[enigme].answers[index]}/>
                                    </div>)
                            } else if (typeof enigmes[enigme].answers[index] === 'number') {
                                return (
                                    <div key={index}>
                                        <FormForNumbers index={index} handleChange={handleFormChange} answer={enigmes[enigme].answers[index]}/>
                                    </div>)
                            } else {
                                return (
                                    <div key={index}>
                                        <FormDeroulant index={index} handleChange={handleFormChange} answer={enigmes[enigme].answers[index]}/>
                                    </div>)
                            }
                        })}
                    </div>
                    
                    <form className="row boup" onSubmit={handleSubmit}>
                        <button className="btn btn-default btn-lg"
                                type="submit">
                                    <h4>Vérifier votre réponse</h4>
                        </button>
                    </form>

                    <button
                        onClick={() => ResetAndBack()}
                        className="btn btn-primary btn-lg btn-block">
                        Revenir aux indices
                    </button>
                </div> 
            );
        }
    }

    return renderForm();
}