/*
      VERSION DU CARNET AVEC ENIMES AUX VASES
*/

const MUSEEROMA = [
    {
      page: 1,
      lib: "Première énigme !",
      texteIndice: ["Rien à ré-écrire pourtant", "Pas vraiment besoin de déplacer quoi que ce soit", "Retourner (lisez à l'envers)"]
    },
    {
      page: 3,
      lib: "A bien voir...",
      texteIndice: [
        "Chronologie fait sens",
        "Classez les plantes d'une introduction la plus ancienne à la plus récente",
        "Cypres"
      ]
    },
    {
      page: 5,
      lib: "Liaisons dangereuses",
      texteIndice: [
        "Détail sur l'homme",
        "Inversion",
        "Il est droitier (sa montre est à gauche mais photo inversée)"
      ]
    },
    {
      page: 8,
      lib: "Dessins trompeurs",
      texteIndice: [
        "Les vignettes sont bien les étiquettes listant les pièces",
        "Rotation de l'un",
        "Vingt (car le dernier est retourné, le 6 devient un 9)"
      ]
    },
    {
      page: 10,
      lib: "Ce n'est pas un jeu",
      texteIndice: [
        "L'auteur du carnet",
        "et l'auteur de la sculpture",
        "A.Pelet (il est inscrit à droite de la statue)"
      ]
    },
    {
      page: 12,
      lib: "Lumières",
      texteIndice: ["Et non 110 n'est pas la réponse", "Les recyclés sont aussi recyclable", "Cent onze"]
    },
    {
      page: 15,
      lib: "Vision",
      texteIndice: [
        "Comparez",
        "Il y a des pots en trop",
        "Aube"
      ]
    },
    {
      page: 17,
      lib: "Affaire de sens",
      texteIndice: [
        "pas une lettre",
        "L n'est pas une lettre mais un membre",
        "32 (Ailes, car il y a 16 oiseaux dans mosaïque)"
      ]
    },
    {
      page: 19,
      lib: "Balade à la tour Magne",
      texteIndice: [
        "Imaginez vous déplacer si vous êtiez tout petit",
        "Si doute comptez les lettres de la réponse",
        "Temple de diane"
      ]
    },
    {
      page: 21,
      lib: "Verre",
      texteIndice: [
        "Trouvez la bonne vitrine",
        "Trouvez les points communs comptés",
        "Anses"
      ]
    },
    {
      page: 23,
      lib: "Courses",
      texteIndice: [
        "Trouvez la bonne vitrine",
        "Des lampes à huiles montrent la réponse",
        "biche"
      ]
    },
    {
      page: 26,
      lib: "Prise de hauteur",
      texteIndice: [
        "Problème central",
        "Penchez-vous mais pas au bord !",
        "iiii"
      ]
    },
    {
      page: 28,
      lib: "Horizon",
      texteIndice: ["Comparez", "Il manque quelque chose, venant d'où ?", "Eglise Saint Paul"]
    },
    {
      page: 30,
      lib: "Alibis",
      texteIndice: [
        "Il y a bien un alibi qui contient une erreur factuelle",
        "Le wine bar est bien au RDC, l'alibi est crédible",
        "Le palais de justice n'a que 3 étages, impossible d'être au 5ème"
      ]
    },
    {
      page: 34,
      lib: "Sésame",
      texteIndice: [
        "Le sésame pour le musée",
        "Il tient dans la main, permet d'entrer, superposez-le",
        "https://bit.ly/m/2498"
      ]
    },
    {
      page: 99,
      lib_page: "Terminer le parcours",
      lib: "Vous avez terminé le parcours ? Bravo ! Alors où se cache la mosaïque ?",
      lib_win_title: "Bravo ! Félicitations !",
      lib_win_texte: "Votre temps a été enregistré. Retournez voir l'animateur.",
      questions: ["Quelle type de voie ?", "Quel nom ?", "Qui est le coupable du vol ?"],
      answers: [
        ["Impasse", "Square", "Rue", "Allée", "Chemin", "Route"],
        "Audiete",
        ["Mégane Reguies", "Pierre Arancif", "Julien Bondoré"]]
    }
  ];

export default MUSEEROMA;