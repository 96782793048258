import React, { useState, useContext, useEffect } from 'react';
import UserContext from './UserContext';
import { getSessionFromDB } from '../Sessions.jsx';

const LogOnRoute = () => {
  const styles = {
    form: {
      maxWidth: '90%',
      margin: '2rem auto',
      padding: '1rem',
      backgroundColor: 'black',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    formGroup: {
      marginBottom: '1rem',
    },
    label: {
      display: 'block',
      marginBottom: '0.5rem',
      fontWeight: 'bold',
      color: 'white',
      fontSize: '2rem',
    }
  };
  
    // RECUPERATION CONTEXTE
    const { session, setSession } = useContext(UserContext);
    const [error, setError] = useState('');

    const  getTeamCode = async () => {
      const params = new URLSearchParams(window.location.search);
      if (params.get("vi") == null) {
        return
      }
      await getSessionFromDB(params.get("vi")).then((data) => {
        console.log(data);
        if (data.error == null) {
          setSession({session, type: 'creation', value: data });}
        else
          setError(data.error);
      });
    }

    useEffect(() => {
      getTeamCode();
    }, []);

    if ( error !== '')
      return (
        <div style={styles.form}>
            <label  style={styles.label}>
              <h2>Oups !</h2>
              <p>{error}</p>
            </label>
        </div>
      );

    if (session.step == -1)
      return (
        <div style={styles.form}>
            <label style={styles.label}>
              <h2>Oups !</h2>
              <p>Je ne sais pas pour quel parcours vous aider ?!</p>
              <p>Un code doit être indiqué dans la barre du navigateur.</p>
              <p>https://indices.exitgame.fr/?vi=EXEMPLE_DE_CODE</p>
            </label>
        </div>
      );
};


export default LogOnRoute;