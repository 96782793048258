
import EmailSender from "../shared/EmailSender";
import { useEffect, useMemo, useState, useContext } from "react";
import Timer from "./Timer.jsx";
import UserContext from '../shared/UserContext.js';

export default function TopPageLogo() {
    const [bugReportToRender, setBugReportToRender] = useState(false);
    const [msgReport, setMsgReport] = useState("");
    const { session, setSession } = useContext(UserContext);
    const enigme = session.currentPage;
    const render = (session.step > 1)?true:false;
    const renderButton = (session.currentPage > 0)?true:false;
    let msg = "";

    useEffect(() => {
        if(msgReport.length > 0) setMsgReport("");  //RESET DU MESSAGE DE MAIL QUAND CHANGEMENT DE PAGE
    }, [session.currentPage]);

    if (!render) return;

    const showForm = (e) => {
        setBugReportToRender(true);
      };

    const handleCloseFormBugReport = (success) => {
        setBugReportToRender(false);
        setMsgReport("Retour bien transmis, merci de votre aide !")
      };

    const isTimerToRender = () => {
        if (session.step < 3) return true;
        return false;
    }

    const showBugButton = () => {

        if (renderButton) {
            return (
                <div className="bloc-bug" onClick={() => showForm()}>
                    Signaler un problème dans l'énigme
                </div>
            );
        } else return ("");

    };
    
    const renderTopPageLogo = () => {
        return (
            <div className="hero">
                <EmailSender
                    render={bugReportToRender}
                    enigmes={session.route}
                    enigme={enigme}
                    eventCloseFormBugReport={handleCloseFormBugReport}
                />
                
                <div className="hero-content">

                    <div className="col">
                    
                    </div>
                    <div className="col logo">
                        <Timer
                            datedepart={session.datedepart}
                            render={isTimerToRender()}
                        />
                    </div>
                    <div className="col">
                        {showBugButton()}
                    </div>
                </div>
                <center><h3>{msgReport}</h3></center>
            </div>
        );
    };

    return renderTopPageLogo();
}