import React, { useState, useContext } from 'react';
import Particules from "../shared/Particules";
import UserContext from '../shared/UserContext.js';

export default function FormForNumbers({index, handleChange, answer}) {
    const { session, setSession } = useContext(UserContext);
    const [firstNumber, setFirstNumber] = useState(1);

    const enigmes = session.route;
    const enigme = session.currentPage;

    const handleFirstNumberChange = (e) => {
        const res = parseInt(e);
        setFirstNumber(res);
        if( res === answer)
            handleChange(index, true);
        else
            handleChange(index, false);
    };

    const renderForm = () => {
        return (
            <div className="container text-center confettis">
                <div className="row">
                    <h4>{enigmes[enigme].questions[index]}</h4>
                </div>
                <div className="row boup">
                    <button
                        className="btn btn-default btn-lg compteur"
                        onClick={() => handleFirstNumberChange(firstNumber-1)}
                    >
                    -
                    </button>

                    <span className='chiffre-central-compteur'>
                        {firstNumber}
                    </span>
                    <button
                        className="btn btn-default btn-lg compteur"
                        onClick={() => handleFirstNumberChange(firstNumber+1)}
                    >
                    +
                    </button>
                </div>
            </div> 
        );
    }

    return renderForm();
}