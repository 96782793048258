import React, { useState, useContext } from 'react';
import Particules from "../shared/Particules";
import UserContext from '../shared/UserContext.js';

export default function FormDeroulant({index, handleChange, answer}) {
    const { session, setSession } = useContext(UserContext);
    const [firstNumber, setFirstNumber] = useState(1);
    const [reponse, setReponse] = useState('');
    const [selectedOption1, setSelectedOption1] = useState('');

    const enigmes = session.route;
    const enigme = session.currentPage;
    const listAnswers = enigmes[enigme].answers[index];

    if (typeof enigmes[enigme].answers[index] === 'undefined') {
        return ("Oups ! Erreur :(, la réponse n'est pas configurée");
    } else {

        // LA BONNE REPONSE EST FORCECMENT LA DERNIER DU TABLEAU :(

        var reponseAttendue = listAnswers[ listAnswers.length - 1 ].toUpperCase();
    }

    const handleChangeForm = (e) => {
        let text = e.target.value;
        console.log("Options selectionnée: "+text);
        console.log("--> Réponse attendue: "+reponseAttendue);
        setSelectedOption1(text);
            if (text.toString().toUpperCase() === reponseAttendue) {
                handleChange(index, true);
                console.log("Bon choix fait, true");
                //setFormState(2);
                //handleAnswer(enigme);
            } else {
                handleChange(index, false);
            }
    };

    const renderForm = () => {

        return (
            <div className="container text-center confettis">
                <div className="row">
                    <h4>{enigmes[enigme].questions[index]}</h4>
                </div>
                <div className="row boup">
                    <select
                        value={selectedOption1}
                        onChange={handleChangeForm}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                        <option value="">Sélectionnez une option</option>
                        {
                            listAnswers.map( (answer, index) => {
                               return <option key={index} value={answer}>{answer}</option>
                            } )
                        }
                    </select>
                </div>
            </div> 
        );
    }

    return renderForm();
}