import React, { useState, useContext } from 'react';
import Particules from "../shared/Particules";
import UserContext from '../shared/UserContext.js';

export default function FormString({index, handleChange, answer}) {
    const { session, setSession } = useContext(UserContext);
    const [firstNumber, setFirstNumber] = useState(1);
    const [reponse, setReponse] = useState('');

    const enigmes = session.route;
    const enigme = session.currentPage;

    if (typeof enigmes[enigme].answers[index] === 'undefined') {
        return ("Oups ! Erreur :(, la réponse n'est pas configurée");
    } else {
        var reponseAttendue = enigmes[enigme].answers[index].toUpperCase();
        console.log("Reponse attendue= "+reponseAttendue);
    }

    const handleFirstNumberChange = (e) => {
        const res = parseInt(e);
        setFirstNumber(res);
        if( res === answer)
            handleChange(index, true);
        else
            handleChange(index, false);
    };

    const handleChangeForm = (e) => {
        let text = e.target.value;  
        let nouvelAffichage = '';
        let message = '';
        for (let i = 0; i < reponseAttendue.length; i++) {
            if (text.length > i) {
                nouvelAffichage += text[i].toUpperCase();
            } else {
                nouvelAffichage += '_';
            }
        }
        
        if ( text.toString().length === reponseAttendue.length ) {
            if (text.toString().toUpperCase() === reponseAttendue) {
                handleChange(index, true);
                //setFormState(2);
                //handleAnswer(enigme);
            } /*else {            //////  SUPPRIME CAR LA VERIF EST FAITE APRES
                handleChange(index, false);
                message = text+' n\'est pas la bonne réponse. Réessayez !';
                nouvelAffichage = '_'.repeat(reponseAttendue.length);
                text = '';
            }*/
        }
        setReponse(text);
    };

    const renderForm = () => {

        return (
            <div className="container text-center confettis">
                <div className="row">
                    <h4>{enigmes[enigme].questions[index]}</h4>
                </div>
                <div className="row boup">
                   <div className='input'>
                        <input
                            type="text"
                            value={reponse}
                            onChange={handleChangeForm}
                            maxLength={reponseAttendue.length}
                            autoCapitalize="characters"
                            placeholder='Entrez votre réponse'
                        />
                    </div>
                </div>
            </div> 
        );
    }

    return renderForm();
}