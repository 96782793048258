
import { useEffect, useMemo, useState, useReducer } from "react";
import "./styles.css";
import Menu from "./components/Menu";
import ContentIndices from "./components/clues/ContentIndices";
import TopMessage from "./components/top/TopMessage.jsx";
import TopPageLogo from "./components/top/TopPageLogo";
import {getSession, getClt, SessionReducer} from "./components/Sessions.jsx";
import AdminResults from "./components/admin/AdminResults.jsx"
import FormPhone from "./components/FormPhone.jsx";
import Form1stPuzzle from "./components/Form1stPuzzle.jsx";
import UserContext from './components/shared/UserContext';
import LogOnRoute from "./components/shared/LogOnRoute.jsx";

export default function App() {

  const [session, setSession] = useReducer( SessionReducer, getSession() );

  console.log("APP.js - Session:");
  console.log({session});

  const renderAdmin = (session.teamcode.toUpperCase() === 'ADMINZ')?true:false; //<AdminResults/>
  
  return (
          
    <div className="App">
      <UserContext.Provider value={{ session, setSession }}>
        <LogOnRoute/>
        <FormPhone/>
        <Form1stPuzzle/>
        {renderAdmin?<AdminResults/>:<></>}
        <TopPageLogo/>
        <Menu/>
        <ContentIndices/>
      </UserContext.Provider>
    </div>
    
  );
  
}
