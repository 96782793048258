const VP_ENG = [
  {
      page: 5,
      texteIndice: [
        "A hot-air balloon problem",
        "The name of a game is to find",
        "Drawing to survive",
        "Answer: The last straw"]
    },
    {
      page: 7,
      texteIndice: [
        "A game of sums",
        "32",
        "Answer: 12, because 6+12+14 = 32"]
    },
    {
      page: 9,
      texteIndice: [
        "Arborigenous: Which comes from Autralia",
        "Answer: Koala"
      ]
    },
    {
      page: 11,
      texteIndice: [
          "Sésostris is the oldest",
          "Answer: 07 because SESOSTRIS / PERIClES"
      ]
    },
    {
      page: 13,
      texteIndice: [
        "There is lime on the square",
        "There is palm on the square",
        "Answer: Oak"
      ]
    },
    {
      page: 15,
      texteIndice: [
        "keep your head up",
        "keep letters where the engraving is damaged",
        "Answer: Bouteille(s)"
      ]
    },
    {
      page: 17,
      texteIndice: [
        "18 _  _",
        "1828 + 150 = 1978"
      ]
    },
    {
      page: 20,
      texteIndice: [
        "What's missing from its name?",
        "the hand (it's missing on the clock)"
      ]
    },
    {
      page: 22,
      texteIndice: [
        "It's not twenty-two hours",
        "The last effort is the key",
        "17 hours, at the end 30m are made without recoil"
      ]
    },
    {
      page: 24,
      texteIndice: [
        "The street lamp",
        "Find the (real) padlock hanging",
        "Perseverez (is engraved on the padlock)"
      ]
    },
    {
      page: 27,
      texteIndice: [
        "The red lines are a red herring",
        "Find the different number",
        "Answer: 9"
      ]
    },
    {
      page: 30,
      texteIndice: [
        "They are attached to the hull",
        "Answer: 2"
      ]
    },
    {
      page: 31,
      texteIndice: [
        "Fold, message appears",
        "Count the balconies",
        "Answer: 8"
      ]
    },

    {
      page: 34,
      texteIndice: [
        "Guess the opposites of each words",
        "The horseshoes hanging on the wall, the big one too",
        "Answer: 13"
      ]
    },
    {
      page: 37,
      texteIndice: [
        "The answer is not 12 minutes",
        "It's ok to bake the other side later",
        "Answer: 9 minutes"
      ]
    },
    {
      page: 39,
      texteIndice: [
        "replace",
        "Answer: He has a scar"
      ]
    },
    {
      page: 41,
      texteIndice: [
        "The points form a letter",
        "Answer: A"
      ]
    },
    {
      page: 99,
      lib_page: "Last step - Check the answer to the whole case!",
      lib: "You've completed the course? Congratulations! How's the enquiry going?",
      lib_win_title: "Well done! Congratulations!",
      lib_win_texte: "Thanks again for taking part in one of our games!",
      questions: ["What's the culprit's name?"],
      answers: [["Dédé le Fada", "Henri la cambriole", "Gégé brise-doigts", "Tony Monténé", "Al Pacone", "Larsène Tapin","Jo l'enguille", "Jo le bagnard"]]
    }
  ];

export default VP_ENG;
  