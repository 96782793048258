import { useContext } from "react";
import UserContext from './shared/UserContext';

export default function Menu() {
  //Etats
  const { session, setSession } = useContext(UserContext);
  const render = (session.currentPage < 0 && session.step > 1)?true:false;

  //Comportements
  if (!render) return;

  const eventChoixEnigme = (index, page) => {

  }

  function getButtonLabel(index, libelle) {
    if (typeof libelle === 'undefined') {
      return ('Page '+index);
    } else {
      return (libelle);
    }
  }

  //Affichage
  return (
  <div className="menu">
    <div className="menu-titre text-center">
      <h4>Choisissez la page de l'énigme pour l'indice</h4>
    </div>
    <div className="menu-content text-center">
      {session.route.map((enigme, index) => (
        <button
          key={enigme.page}
          className="btn btn-default btn-lg"
          onClick={() => setSession({session, type:"renderCluePage", value:index})}
        >
          {getButtonLabel(enigme.page, enigme.lib_page)}
        </button>
      ))}
    </div>
  </div>
  );
}
        